* {
  box-sizing: border-box;
  font-family: "mrs-eaves";
}

#root {
  height: 100%;
}

html, body {
  height: 100%;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: 1.5;

}

.cursive {
  font-family: "la-bohemienne", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.mrs-eaves-petite {
  font-family: mrs-eaves-roman-all-petite-c, serif;
  font-weight: 400;
  font-style: normal;
}

.mrs-eaves-roman {
  font-family: "mrs-eaves", serif;
  font-weight: 400;
  font-style: normal;
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}


h1 {
  font-family: mrs-eaves-roman-all-petite-c, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  line-height: 0.75;
}

.center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.homepage {
    height: 100%;
    padding: 5em
}

.header-img {
  width: 100%;
  max-width: 25em;
  height: auto;
}

.homepage label {
  padding-right: 0.25em;
}

.rsvp {
  padding: 3em;
  height: 100%;
}

input, textarea {
  font-size: 1em;
  padding: .25em;
  margin: .5em auto;
  border-radius: 0.25em;
  border: 2px solid black;
  display: inline-block;
  transition: all .5s ease 0s;
  max-width: 100%;
}

input:focus, input:hover, textarea:focus {
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.37);
}

input[type="submit"] {
  padding: .25em 1em;
  background: black;
  color: white;
  cursor: pointer;
}

input[type="submit"]:hover, input[type="submit"]:focus {
  padding: .25em 1em;
  background: white;
  color: black;
  cursor: pointer;
}

.rsvp form {
  text-align: left;
  margin-top: 2em;
}

.rsvp input, .rsvp textarea {
  width: 100%;
}

.rsvp .input-group {
  margin: 1em auto;
}
.rsvp .radio label {
  font-family: mrs-eaves;
  font-size: 1em;
  margin-left: 0.5em;
  margin-right: 1em;
}

.rsvp .radio input {
  margin: 1em 0 0 0em;
  width: auto;
}

.rsvp .wrapper {
  width: 100%;
  max-width: 40em;
  margin: 0 auto;
}

